import { Box, Flex, IconButton, Link as CLink, Show, StackDivider, useOutsideClick, VStack } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, Route, Switch, useLocation } from "react-router-dom";

import { Icon, Text } from "ui-kit";
import { Cart, Close, Home, MRLogo, Shirt, Store, Tag, Avatar, Shopify, LightspeedBlack, Handshake } from "ui-kit/icon";

import { getIsLoggedIn } from "store/selectors/auth";
import {
  getUserData,
  getUserNames,
  getRetailerType,
  RetailerType,
  getUserRetailerIsShellAccount
} from "store/selectors/user";

import { SHOPIFY_LOGIN_URL } from "constants/shopify";

// SR logo
import { LogoNoSwap as logo } from "static/images";
import { EditIcon, LinkIcon } from "@chakra-ui/icons";
import HeaderImpersonationBox from "components/layout/header/header-impersonation-box/header-impersonation-box";
import { SideNavAreaName } from "components/layout/side-nav/side-nav-area-name";
import { SideNavCartLink, SideNavLink } from "components/layout/side-nav/side-nav-link";
import NewFeaturePanel from "components/layout/side-nav/new-feature-panel";
import { SHOW_NEW_FEATURE_PANEL } from "components/layout/constants";
import { LIGHTSPEED_LOGIN_URL } from "constants/lightspeed";
import { ApiFeatureFlagged } from "hooks/feature-flags";

export const SideNavLinkList = props => (
  <Flex as="ul" direction="column" gap="1" listStyleType="none" w="full" {...props} />
);

const ListInventoryNavButton = ({ retailerType }: { retailerType: RetailerType }) => {
  const isShellAccount = useSelector(getUserRetailerIsShellAccount);
  const TOOLTIP_DESCRIPTION = "List your items for sale on Max Retail";

  if (retailerType === "SHOPIFY") {
    return (
      <SideNavLink
        icon={Shopify}
        external
        isExternal
        href={SHOPIFY_LOGIN_URL}
        isLocked={isShellAccount}
        tooltipDescription={TOOLTIP_DESCRIPTION}
        _hover={{
          backgroundColor: isShellAccount ? "gray.200" : "gray.200"
        }}
      >
        List Inventory
      </SideNavLink>
    );
  }
  if (retailerType === "LIGHTSPEED") {
    return (
      <SideNavLink
        icon={LightspeedBlack}
        external
        isExternal
        href={LIGHTSPEED_LOGIN_URL}
        isLocked={isShellAccount}
        tooltipDescription={TOOLTIP_DESCRIPTION}
        _hover={{
          backgroundColor: isShellAccount ? "gray.200" : "gray.200"
        }}
      >
        List Inventory
      </SideNavLink>
    );
  }
  return (
    <SideNavLink
      icon={Tag}
      to="/addListing"
      isLocked={isShellAccount}
      tooltipDescription={TOOLTIP_DESCRIPTION}
      _hover={{
        backgroundColor: isShellAccount ? "gray.200" : "gray.200"
      }}
    >
      List Inventory
    </SideNavLink>
  );
};

const SideNav = ({ onClose = () => {} }) => {
  const location = useLocation();
  const ref = useRef(null);
  const retailerType = useSelector(getRetailerType);
  const { first_name } = useSelector(getUserNames);
  const user = useSelector(getUserData);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const isShellAccount = useSelector(getUserRetailerIsShellAccount);

  useEffect(() => onClose(), [location.key]);

  useOutsideClick({
    ref,
    handler: () => onClose()
  });

  return (
    <Box ref={ref} as="nav" bg="white" flexBasis="300px" flexShrink={0} minH="100vh" zIndex="overlay">
      <Flex direction="column" h="100vh">
        <Box flexGrow={1} px="15px" pt="27px" mb="auto">
          <Show below="lg">
            <IconButton aria-label="close-button-side-nav" onClick={onClose} fontSize="xl" mb="4" variant="unstyled">
              <Icon as={Close} boxSize="10" />
            </IconButton>
          </Show>

          <Show above="lg">
            <Box pl="3" mb="6">
              <Link to="/">
                <img src={logo} alt="Max Retail logo" className="header__logo__img" />
              </Link>
            </Box>
          </Show>

          <Switch>
            <Route path="/admin*">
              <VStack alignItems="flex-start" gap="2" divider={<StackDivider borderColor="gray.100" />}>
                <SideNavAreaName>
                  <CLink as={Link} to="/">
                    &laquo; Back
                  </CLink>
                </SideNavAreaName>
                <SideNavLinkList>
                  <SideNavLink icon={MRLogo} exact to="/admin">
                    Admin
                  </SideNavLink>
                  <SideNavLink icon={EditIcon} exact to="/admin/verifications">
                    Verifications
                  </SideNavLink>
                  <ApiFeatureFlagged featureName="retailers.pim.show-admin-link">
                    <SideNavLink icon={EditIcon} exact to="/admin/pim">
                      PIM - Image management
                    </SideNavLink>
                    <SideNavLink to="/search" icon={Cart}>
                    Max Search 2.0
                    </SideNavLink>
                  </ApiFeatureFlagged>
                  <SideNavLink icon={LinkIcon} exact to="/admin/matching">
                    Matching
                  </SideNavLink>
                  <SideNavLink icon={Tag} exact to="/admin/catalog">
                    Product Catalog
                  </SideNavLink>
                  <SideNavLink icon={Tag} exact to="/admin/rtr">
                    RTR Upload
                  </SideNavLink>
                  <SideNavLink icon={Tag} exact to="/admin/csv">
                    CSV Upload
                  </SideNavLink>
                </SideNavLinkList>

                <HeaderImpersonationBox showLinks={false} />
              </VStack>
            </Route>
            <Route path="/:path*">
              <VStack alignItems="flex-start" gap="2" divider={<StackDivider borderColor="gray.100" />}>
                <SideNavLinkList gap="3">
                  <SideNavAreaName>Menu</SideNavAreaName>
                  <Box as="li" listStyleType="none">
                    <SideNavLinkList>
                      <SideNavLink
                        icon={Home}
                        to="/"
                        isActive={(_, currentUrl) =>
                          currentUrl.pathname === "/" && !currentUrl.search.includes("tab=requests")
                        }
                      >
                        Home
                      </SideNavLink>
                      {!isShellAccount && (
                        <SideNavLink
                          icon={Store}
                          to="/orders?from=top_menu"
                          isActive={(match, loc) => {
                            if (!match) return false;

                            return loc.pathname.startsWith("/orders");
                          }}
                        >
                          My Orders
                        </SideNavLink>
                      )}
                      <SideNavLink
                        isLocked={isShellAccount}
                        tooltipDescription="See all your items listed for sale on Max Retail"
                        to="/mylistings"
                        exact
                        icon={Shirt}
                        _hover={{
                          backgroundColor: isShellAccount ? "gray.200" : "gray.200"
                        }}
                      >
                        My Inventory
                      </SideNavLink>
                      <ListInventoryNavButton retailerType={retailerType} />
                      {!isShellAccount && (
                        <SideNavLink to="/browse" exact icon={Cart}>
                          Shop Marketplace
                        </SideNavLink>
                      )}
                      <SideNavLink to="/refer-friend/" icon={Handshake}>
                        Refer a Friend
                      </SideNavLink>
                      <SideNavCartLink />
                    </SideNavLinkList>
                  </Box>
                </SideNavLinkList>

                <SideNavLinkList gap="3">
                  <Box as="li" listStyleType="none">
                    <SideNavLinkList>
                      {!isShellAccount && (
                        <SideNavLink to="/requestListing" exact strict>
                          Request What You Need
                        </SideNavLink>
                      )}
                      <SideNavLink
                        to="/?tab=requests"
                        isActive={(_, currentUrl) =>
                          currentUrl.pathname !== "/mylistings" && currentUrl.search.includes("tab=requests")
                        }
                        isLocked={isShellAccount}
                        tooltipDescription="View items requested by other sellers"
                        _hover={{
                          backgroundColor: isShellAccount ? "gray.200" : "gray.200"
                        }}
                      >
                        View Seller Requests
                      </SideNavLink>
                      <SideNavLink
                        isLocked={isShellAccount}
                        tooltipDescription="View the brands your store carries"
                        to="/brands"
                        _hover={{
                          backgroundColor: isShellAccount ? "gray.200" : "gray.200"
                        }}
                      >
                        My Brands
                      </SideNavLink>
                    </SideNavLinkList>
                  </Box>
                </SideNavLinkList>

                <HeaderImpersonationBox />
              </VStack>
            </Route>
          </Switch>
        </Box>
        {isLoggedIn && (
          <Flex
            as={Link}
            to="/knowledge-center/"
            target="_blank"
            d="inline-flex"
            flexGrow={0}
            h="40px"
            p="2"
            marginLeft="4"
            gap="4"
          >
            <Box color="blue.darkest">
              <Text textStyle="body" mb="0" lineHeight="1.3" fontSize="14px" opacity=".6">
                Knowledge Center
              </Text>
            </Box>
          </Flex>
        )}

        <Flex as={Link} to="/terms/" d="inline-flex" flexGrow={0} h="40px" p="2" marginLeft="4">
          <Box color="blue.darkest">
            <Text textStyle="body" mb="0" lineHeight="1.3" fontSize="14px" opacity=".6">
              Terms and Conditions
            </Text>
          </Box>
        </Flex>

        <Flex
          as={Link}
          to="/privacy-policy/"
          d="inline-flex"
          flexGrow={0}
          h="40px"
          p="2"
          marginBottom="4"
          marginLeft="4"
          gap="4"
        >
          <Box color="blue.darkest">
            <Text textStyle="body" mb="0" lineHeight="1.3" fontSize="14px" opacity=".6">
              Privacy Policy
            </Text>
          </Box>
        </Flex>

        <Box bgColor="white" sx={{ position: "sticky", bottom: "0" }}>
          {SHOW_NEW_FEATURE_PANEL && <NewFeaturePanel />}

          <Flex
            as={Link}
            to="/settings"
            d="inline-flex"
            flexGrow={0}
            w="full"
            h="80px"
            p="5"
            gap="4"
            borderTop={SHOW_NEW_FEATURE_PANEL ? "1px solid #E4E8EA" : undefined}
            shadow={SHOW_NEW_FEATURE_PANEL ? undefined : "0 -3pt 6pt hsla(0, 0%, 0%, 0.1)"}
          >
            <Flex justify="center" align="center" borderRadius="md" bg="blue.light" boxSize="10">
              <Icon as={Avatar} boxSize="1.75rem" />
            </Flex>

            <Box color="blue.darkest">
              <Text textStyle="body" mb="0" lineHeight="1.3">
                {first_name ?? "N/A"}
              </Text>
              <Text opacity="0.5" fontSize="xs" textStyle="body4" lineHeight="1">
                {user.email}
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default SideNav;
