import { createContext, useContext } from "react";
import { useQuery } from "@tanstack/react-query";

import flagsApi from "api/flags-api";

interface IFeatureFlag {
  enabled: boolean
  feature_name: string
}

const FeatureFlagContext = createContext({ featureFlags: [], isSuccess: false });

export const useFeatureFlags = () => useContext(FeatureFlagContext);

export const FeatureFlagProvider = ({ children }) => {
  const { data, isSuccess } = useQuery(["feature-flags"], () => flagsApi.getFlags());

  return (
    <FeatureFlagContext.Provider value={{ featureFlags: data, isSuccess }}>{children}</FeatureFlagContext.Provider>
  );
};

export const getIsFeatureFlagEnabled = ({ featureFlags, isSuccess, feature }: { featureFlags: IFeatureFlag[], isSuccess: boolean, feature: string }) => {
  const featureFlag = isSuccess && featureFlags.find(flag => flag.feature_name === feature);
  return Boolean(featureFlag) && featureFlag.enabled;
}